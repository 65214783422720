<template>
  <div class="body1">
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{name: 'home'}">{{ $t('home') }}</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{ $t('privacy_policy') }}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div style="margin: 30px 10px;">
      <h2>Privacy-Policy</h2>
      <p>This privacy policy sets out how "Jidu Travel" (trading as JiduTravel) uses and protects any information that you give "JiduTravel" when you use this website.</p>
      <p>"JiduTravel" is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>
      <p>"JiduTravel" may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 01.08.2017.</p>
      <h3>What we collect</h3>
      <p>We may collect the following information:</p>
      <ul>
        <li>name and job title</li>
        <li>contact information including email address</li>
        <li>demographic information such as postcode, preferences and interests</li>
        <li>other information relevant to customer surveys and/or offers</li>
        <li>What we do with the information we gather</li>
      </ul>
      <p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:</p>
      <p>Internal record keeping.</p>
      <p>We may use the information to improve our products and services.</p>
      <p>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</p>
      <p>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.</p>
      <h3>Security</h3>
      <p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped>

</style>